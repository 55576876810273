export function request () {
  return [
    {
      symbol: 'OTCM',
      tierCode: 'QX',
      id: 180609,
      publisherName: 'OTC Disclosure & News Service',
      title: 'test & more  test',
      url: '/company/research/180609/content',
      rank: 1,
      publishDate: '2017-10-13 10:17:35.0',
      periodEndDate: '2017-09-30 00:00:00.0'
    },
    {
      symbol: 'IMPZY',
      tierCode: 'QX',
      id: 180447,
      publisherName: 'OTC Disclosure & News Service',
      title: 'Implanet reports a 19% increase in Jazz sales for first-half 2017 on the European market',
      url: '/company/research/180447/content',
      rank: 1,
      publishDate: '2017-10-05 06:56:53.0',
      periodEndDate: '2017-09-28 00:00:00.0'
    },
    {
      symbol: 'OTCM',
      tierCode: 'QX',
      id: 180275,
      publisherName: 'OTC Disclosure & News Service',
      title: 'Sidoti & Company, LLC Report - Septmeber 29, 2017',
      url: '/company/research/180275/content',
      rank: 1,
      publishDate: '2017-09-29 12:25:00.0',
      periodEndDate: '2017-09-29 00:00:00.0'
    },
    {
      symbol: 'IMPZY',
      tierCode: 'QX',
      id: 179659,
      publisherName: 'OTC Disclosure & News Service',
      title: 'Implanet SA - Reports 1H17 Results - JAZZ Continues to Drive Sales',
      url: '/company/research/179659/content',
      rank: 1,
      publishDate: '2017-09-20 06:58:04.0',
      periodEndDate: '2017-09-19 00:00:00.0'
    }
  ]
}
