import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Flex, Box } from 'grid-styled'
import {
  FaFacebook,
  FaLinkedin,
  FaBan,
  FaExternalLinkAlt
} from 'react-icons/fa'
import FaTwitter from '../FaTwitter'
import styles from './CompanyQuoteProfile.module.scss'
import { Link } from 'react-router-dom'
import api from '../../api/api'
import { compareAddress } from '../../utils/compare-address'
import { getCorpStructure } from '../../api/otc/company/qaravan'
import { logPageview } from '../../analytics'

import { format } from '../../utils/locale'
import { isMobile } from '../../utils/is-mobile'
import { stateText, countryText, yearText } from '../../utils/text'

import * as Routes from '../../constants/Routes'
import Outline from '../Outline'
import Accordion from '../Accordion'
import List from '../List'
import Loading from '../Loading'
import BankAccordian from '../BankAccordian'
import ExternalLink from '../ExternalLink'
import Flexgrid from '../Flexgrid'
import CompanyQuoteInfoTitle from '../AccordionItems/CompanyQuoteInfoTitle'
import CompanyQuotePersonProfile from '../AccordionItems/CompanyQuotePersonProfile'
import CompanyQuoteIncorporationInfo from '../AccordionItems/CompanyQuoteIncorporationInfo'
import CompanyQuoteWebsiteDisclosureInfo from '../AccordionItems/CompanyQuoteDisclosureWebsiteInfo'

const hostname = window.location.origin

const formatDirectors = (directors, officers) => {
  if (!directors) return []

  return directors.map(director => {
    // append officer title to director obj if borad property is empty
    const officier = officers.find(officer => officer.name === director.name && officer.title.toLowerCase().includes('ceo'))
    if (officier && (director.boards === undefined || director.boards === '')) {
      director.boards = officier.title
    }

    return {
      title: director.name,
      hasContent: !(!director.biography || director.biography === ''),
      data: {
        value: !director.premier ? director.boards : director.title + ((director.title && director.boards) ? ', ' : '') + (director.boards ? director.boards : ''),
        desc: director.biography,
        socialButtons: true,
        facebook: director.facebook,
        twitter: director.twitter,
        linkedin: director.linkedin
      }
    }
  })
}

const formatOfficers = (officers) => {
  if (!officers) return []

  return officers.map((officer, index) => {
    return {
      title: officer.name,
      hasContent: !(!officer.biography || officer.biography === ''),
      data: {
        value: officer.title,
        desc: officer.biography,
        socialButtons: true,
        facebook: officer.facebook,
        twitter: officer.twitter,
        linkedin: officer.linkedin
      }
    }
  })
}

const formatInsiders = (insiders) => {
  if (!insiders) return []

  return insiders.map(insider => {
    return {
      title: `${insider.firstName} ${insider.lastName}`,
      hasContent: false,
      data: {
        value: insider.corpEntity
      }
    }
  })
}

const formatAddress = (address) => {
  const addressLines = []

  if (address.address1) addressLines.push(address.address1)
  if (address.address2) addressLines.push(address.address2)
  if (address.addr1) addressLines.push(address.addr1)
  if (address.addr2) addressLines.push(address.addr2)

  const cityState = []
  let cityStateZipStr = ''

  if (address.city && address.city !== '') cityState.push(address.city)
  if (address.state && address.state !== '') cityState.push(address.state)
  else if (address.stateId && address.stateId !== '') cityState.push(address.stateId)

  cityStateZipStr = cityState.join(', ')

  if (address.zip && address.zip !== '') {
    if (cityStateZipStr.length > 0) {
      cityStateZipStr += ' ' + address.zip
    } else {
      cityStateZipStr = address.zip
    }
  }

  if (cityStateZipStr.length > 0) addressLines.push(cityStateZipStr)

  if (address.country && address.country !== '') {
    switch (address.country) {
      case 'US':
      case 'USA':
      case 'United States':
        // skip country name (how it works on otcmarkets.com)
        break

      default:
        addressLines.push(address.country)
        break
    }
  }

  return addressLines
}

const getDisclosureWebsiteUrls = (disclosureUrlOne, disclosureUrlTwo) => {
  const disclosureWebsiteUrls = []

  if (disclosureUrlOne && disclosureUrlOne.trim() !== '') {
    disclosureWebsiteUrls.push(disclosureUrlOne)
  }

  if (disclosureUrlTwo && disclosureUrlTwo.trim() !== '') {
    disclosureWebsiteUrls.push(disclosureUrlTwo)
  }

  return disclosureWebsiteUrls
}

const layouts = {
  default: [
    {
      type: 'flex',
      subtree: [
        {
          type: 'box',
          width: 1 / 3,
          subtree: ['contact']
        },
        {
          type: 'box',
          width: 2 / 3,
          subtree: ['desc']
        }
      ]
    }
  ],
  sm: [
    {
      type: 'flex',
      column: true,
      subtree: ['desc', 'contact']
    }
  ]
}

class CompanyQuoteProfile extends React.PureComponent {
  constructor (props, context) {
    super(props, context)
    this.state = {
      corpStrucData: {
        loaded: false,
        data: [],
        error: null
      },
      companyLogoError: false
    }
  }

  componentDidMount () {
    const title = `OTC Markets | ${this.props.company} | Company Profile`
    logPageview(title)

    if (this.props.info && this.props.info && this.props.info.bankId) {
      getCorpStructure(this.props.info.bankId).then(response => {
        const nodes = response && response.data && response.data.entities
        nodes && nodes.map(node => {
          const relationships = response && response.data && response.data.relationships
          const children = relationships.filter(edge => edge.rssdIdParent === node.rssdId)

          if (children.length > 0) node.children = children
          if (relationships && relationships.find(edge => edge.rssdIdParent === node.rssdId) && !relationships.find(edge => edge.rssdIdOffspring === node.rssdId)) {
            node.isParent = true
            node.children.forEach(nodeChild => {
              nodeChild.parentChildren = node.children.length
            })
          }
        })

        nodes && nodes.forEach((d, i) => {
          if (d.children) {
            d.children.forEach(child => {
              const detail = nodes.find(node => node.rssdId === child.rssdIdOffspring)
              Object.assign(child, detail)
            })
          }
        })

        this.setState(state => ({
          corpStrucData: {
            loaded: true,
            error: null,
            data: nodes || []
          }
        }))
      }).catch(e => {
        this.setState(state => ({
          corpStrucData: {
            loaded: false,
            error: (e && e.message) || 'Something went wrong, please try again.',
            data: state.corpStrucData.data
          }
        }))
      })
    }

    // If anchor is supplied in hash, scroll into view
    const hash = window.location.hash
    if (hash && hash.length > 1) {
      const el = document.getElementById(hash.substr(1))
      if (el) {
        setTimeout(() => { el.scrollIntoView(true) }, 100)
      }
    }
  }

  handleImageError = () => {
    this.setState({ companyLogoError: true })
  }

  profileData () {
    const profile = this.props.info

    const list = [{
      title: 'SIC - Industry Classification',
      hasContent: false,
      data: {
        value: format(profile.primarySicCode, 'Not Available', 'text')
      }
    }, {
      title: 'Incorporation Information',
      hasContent: !!(profile.incorporationInformation && profile.incorporationInformation.length > 1),
      data: {
        value: (profile.incorporationInformation && profile.incorporationInformation[0]) ? stateText(profile.incorporationInformation[0]) + countryText(profile.incorporationInformation[0]) + yearText(profile.incorporationInformation[0]) : 'Not Available',
        list: profile.incorporationInformation || []
      }
    }, {
      title: 'Employees',
      hasContent: false,
      data: {
        value: format(profile.numberOfEmployees, 'Not Available', 'int') +
          (profile.numberOfEmployeesAsOf ? ' as of ' + format(profile.numberOfEmployeesAsOf, '', 'date') : '')
      }
    }, {
      title: 'Shell',
      hasContent: false,
      data: {
        value: format(profile.isShell, 'Not Available', 'bool')
      }
    }, {
      title: 'Bank / Thrift',
      hasContent: false,
      data: {
        value: format(profile.isBankThrift, 'Not Available', 'bool')
      }
    }, {
      title: 'Blank Check',
      hasContent: false,
      data: {
        value: format(profile.blankCheck, 'Not Available', 'bool')
      }
    }, {
      title: 'Blind Pool',
      hasContent: false,
      data: {
        value: format(profile.blindPool, 'Not Available', 'bool')
      }
    }, {
      title: 'SPAC',
      hasContent: false,
      data: {
        value: format(profile.spac, 'Not Available', 'bool')
      }
    }]

    return list.filter(l => l.data.value !== 'No' || l.title === 'Shell')
  }

  renderCorpStructure = () => {
    const parent = this.state.corpStrucData.data.find(d => d.isParent)
    return <BankAccordian bank={parent} level={1} />
  }

  render () {
    const profile = this.props.info
    const security = profile.currentSecurity
    const accent = this.props.accent
    const isExecAddress = profile.execAddr || false
    const directors = formatDirectors([...(profile.premierDirectorList || []).map(dir => ({ ...dir, premier: true })), ...(profile.standardDirectorList || [])], profile.officers)
    const officers = formatOfficers(profile.officers)
    const insiders = formatInsiders(profile.companyInsiders)
    const notes = profile.notes && profile.notes.map(note => {
      return {
        value: note
      }
    })

    const formattedAddress = profile.address1 && formatAddress(profile).map((item, itemIndex) => <p
      key={`address-line-${itemIndex}`}>
      {item}
    </p>)

    const formattedExecAddress = <div className={styles.execAddress}>
      Principal Executive Offices:
      {profile.execAddr && formatAddress(profile.execAddr).map((item, itemIndex) =>
        <p
          key={`address-line-${itemIndex}`}>
          {item}
        </p>)
      }
    </div>

    const isAddressesSame = profile.execAddr && compareAddress(profile, profile.execAddr)

    const disclosureWebsiteUrls = profile && getDisclosureWebsiteUrls(profile.disclosureUrlOne, profile.disclosureUrlTwo)

    const serviceProviders = []

    if (profile.auditors && profile.auditors.length) {
      profile.auditors.forEach((item) => serviceProviders.push(item))
    }

    if (profile.investmentBanks && profile.investmentBanks.length) {
      profile.investmentBanks.forEach((item) => serviceProviders.push(item))
    }

    if (profile.investorRelationFirms && profile.investorRelationFirms.length) {
      // Replace "Investor Relations" label with "Investor Relations/Marketing/Communications"
      profile.investorRelationFirms.forEach(function (item) {
        item.typeName = 'Investor Relations/Marketing/Communications'
      })
      profile.investorRelationFirms.forEach((item) => serviceProviders.push(item))
    }

    if (profile.legalCounsels && profile.legalCounsels.length) {
      profile.legalCounsels.forEach((item) => serviceProviders.push(item))
    }

    if (profile.corporateBrokers && profile.corporateBrokers.length) {
      profile.corporateBrokers.forEach((item) => serviceProviders.push(item))
    }

    const fxExcTierForRpt = security.foreignExchangeTier && security.foreignExchangeTier !== 'No Tier' ? ' | ' + security.foreignExchangeTier : ''
    const rptStatus = profile.reportingStandard ? profile.reportingStandard + fxExcTierForRpt : 'Not Available'

    return <div className={styles.CompanyQuoteProfile}>
      <Flexgrid layouts={layouts}>
        <Box key='contact' className={styles.companyInfo}>
          {(!this.state.companyLogoError) && <div className={styles.companyLogo}>
            <img src={api.link({ otcCompanyFile: true,  url: '/backend-content/company/logo/'+ security.symbol })} alt={profile.name} onError={this.handleImageError} />
          </div>}
          {(this.state.companyLogoError) && <h3 className={styles.companyName}>{profile.name}</h3>}
          {!(formattedAddress || profile.website || profile.phone || profile.email) && 'Not Available'}
          {formattedAddress}
          {isExecAddress && !isAddressesSame && formattedExecAddress}
          {(profile.website || profile.phone || profile.email) && <Outline mode='section2'>
            {profile.website && <ExternalLink href={profile.website} external />}
            {/* Check if on mobile or desktop to decide whether phone number should be hyperlink or not */}
            {isMobile() ? <ExternalLink href={`tel:${profile.phone}`}>{profile.phone}</ExternalLink> : <p>{profile.phone}</p>}
            {profile.email && <ExternalLink target='_self' href={`mailto:${profile.email}`}>{profile.email}</ExternalLink>}
          </Outline>}

          <Flex w={1} className={styles.socialButtons}>
            {profile.facebook && <Box className={classNames(styles.socialButton, styles[accent])}>
              <ExternalLink href={profile.facebook}><FaFacebook /></ExternalLink>
            </Box>}
            {profile.twitter && <Box className={classNames(styles.socialButton, styles[accent])}>
              <ExternalLink href={profile.twitter}><FaTwitter /></ExternalLink>
            </Box>}
            {profile.linkedin && <Box className={classNames(styles.socialButton, styles[accent])}>
              <ExternalLink href={profile.linkedin}><FaLinkedin /></ExternalLink>
            </Box>}
          </Flex>
        </Box>
        <Box key='desc' id='description' className={styles.description}>
          <Outline mode='heading' accent={accent}>Business Description</Outline>
          <div className={styles.descriptionText}>
            {profile.businessDesc || 'Not available'}
          </div>
        </Box>
      </Flexgrid>

      {profile.bankId && <div>
        <Outline mode='invisible' spacing='large' />
        <Outline mode='heading' accent={accent}>Corporate Structure</Outline>
        <Loading
          loaded={this.state.corpStrucData.loaded}
          data={this.state.corpStrucData.data}
          error={this.state.corpStrucData.error}>
          <div>
            {this.renderCorpStructure()}
            <ExternalLink className={styles.viewCta} href={`${hostname}/otc-content/stock/${this.props.company}/corporate-structure/${profile.bankId}`}>
              <div className={styles.view}>View Corportate Structure Diagram <FaExternalLinkAlt /></div>
            </ExternalLink>
          </div>
        </Loading>
      </div>
      }

      <div>
        <Outline mode='invisible' spacing='large' />
        <Outline mode='heading' accent={accent}>Financial Reporting</Outline>
        <Accordion
          compact
          chevron
          list={[{
            title: 'Reporting Status',
            hasContent: false,
            data: {
              value: rptStatus
            }
          }, {
            title: 'Audited Financials',
            hasContent: false,
            data: {
              value: profile.auditStatusDisplay ||
                (profile.auditStatus === 'A' && 'Audited') ||
                (profile.auditStatus === 'U' && 'Unaudited') ||
                (profile.auditStatus === 'N' && 'Not Available') ||
                'Not Available'
            }
          }, {
            title: 'Latest Report',
            hasContent: false,
            data: {
              value: profile.latestFilingUrl ? api.link({
                otcAPI: true,
                // hack to fix incorrect URL from API
                url: '/backend-content' + profile.latestFilingUrl
              }) : 'Not Available',
              link: profile.hasLatestFiling,
              linkTitle: profile.hasLatestFiling ? `${format(profile.latestFilingDate, '', 'date')} ${profile.latestFilingType || ''}` : ''
            }
          }, {
            title: 'CIK',
            hasContent: false,
            visible: !!profile.cik,
            data: {
              value: profile.cik
            }
          }, {
            title: 'Fiscal Year End',
            hasContent: false,
            data: {
              value: profile.fiscalYearEnd || 'Not Available'
            }
          },
          {
            title: 'Company Disclosure Website',
            hasContent: !!(disclosureWebsiteUrls && disclosureWebsiteUrls.length > 1),
            visible: !!(disclosureWebsiteUrls && disclosureWebsiteUrls.length > 0),
            data: {
              value: disclosureWebsiteUrls && disclosureWebsiteUrls.length > 0 ? disclosureWebsiteUrls[0] : 'Not Available',
              link: disclosureWebsiteUrls && disclosureWebsiteUrls.length > 0,
              linkTitle: disclosureWebsiteUrls && disclosureWebsiteUrls[0],
              disclosureUrls: disclosureWebsiteUrls || []
            }
          }
          ]}
          component={CompanyQuoteWebsiteDisclosureInfo}
          titleComponent={CompanyQuoteInfoTitle} />
      </div>

      <div>
        <Outline mode='invisible' spacing='large' />
        <Outline mode='heading' accent={accent}>Company Officers &amp; Contacts</Outline>
        <Loading
          loaded={!!profile}
          data={officers}>
          <Accordion
            compact
            chevron
            list={officers}
            titleComponent={CompanyQuoteInfoTitle}
            component={CompanyQuotePersonProfile} />
        </Loading>
      </div>

      <div>
        <Outline mode='invisible' spacing='large' />
        <Outline mode='heading' accent={accent}>Board of Directors</Outline>
        <Loading
          loaded={!!profile}
          emptyMessage={profile.isProfileVerified ? 'None' : null}
          data={directors}>
          <Accordion
            compact
            chevron
            list={directors}
            titleComponent={CompanyQuoteInfoTitle}
            component={CompanyQuotePersonProfile} />
        </Loading>
      </div>

      <div>
        <Outline mode='invisible' spacing='large' />
        <Outline mode='heading' accent={accent}>Other Company Insiders</Outline>
        <Loading
          loaded={!!profile}
          emptyMessage={profile.zeroInsiders ? 'None' : null}
          data={insiders}>
          <Accordion
            compact
            list={insiders}
            titleComponent={CompanyQuoteInfoTitle} />
        </Loading>
        <div className={styles.footerNote}>
            {`Other Company Insiders are all persons or entities beneficially owning 10% or more of any class of the issuer's securities. Together, officers, directors and other company insiders comprise Company Insiders.`}
        </div>
      </div>

      <div id='service-providers'>
        <Outline mode='invisible' spacing='large' />
        <Outline mode='heading' accent={accent}>Service Providers</Outline>
        <Loading
          loaded={!!profile}
          data={serviceProviders}>
          <Flex w={1} wrap className={styles.serviceProviders}>
            {serviceProviders && serviceProviders.map((item, index) => <Box
              w={[1, 1 / 2]}
              key={`service-provider-block-${index}`}
              className={styles.block}>
              <div className={styles.title}>
                {item.typeName || (item.roles && item.roles[0])}
              </div>
              {item.isProhibited && <Link className={styles.prohibited} to={Routes.DIR_PROH_SERVICE_PROVIDERS}>
                <FaBan /> Prohibited Service Provider
              </Link>}
              <Link className={styles.sponsorName} to={`${Routes.DIR_SERVICE_PROVIDER}/${item.id}?t=${item.typeId}`}>
                <b>{item.name}</b>
              </Link>
              {item.isSponsor && <span className={styles.sponsor}>
                <img src='/icons/sponsor.png' />
                <Link to={Routes.DIR_SPONSORS}>OTCQX Sponsor</Link>
              </span>}
              <br />
              <div className={styles.address}>
                {formatAddress(item).map((item, itemIndex) => <p
                  key={`provider-address-line-${itemIndex}`}>
                  {item}
                </p>)}
              </div>
            </Box>)}
          </Flex>
        </Loading>
      </div>

      <div id='profile-data'>
        <Outline mode='invisible' spacing='large' />
        <Outline mode='heading' accent={accent}>Profile Data</Outline>
        <Accordion
          compact
          chevron
          fullWidth
          list={this.profileData()}
          titleComponent={CompanyQuoteInfoTitle}
          component={CompanyQuoteIncorporationInfo} />
      </div>

      <div>
        <Outline mode='invisible' spacing='large' />
        <Outline mode='heading' accent={accent}>Products and Services</Outline>
        <p className={styles.descriptionText}>
          {profile.productServicesDescription || 'Not available'}
        </p>
      </div>

      <div>
        <Outline mode='invisible' spacing='large' />
        <Outline mode='heading' accent={accent}>Company Facilities</Outline>
        <p className={styles.descriptionText}>
          {profile.facilitiesDescription || 'Not available'}
        </p>
      </div>

      {notes && notes.length > 0 && <div>
        <Outline mode='invisible' spacing='large' />
        <List
          borders
          title='Company Notes'
          accent={accent}
          list={notes} />
      </div>}
    </div>
  }
}

CompanyQuoteProfile.propTypes = {
  company: PropTypes.string.isRequired,
  info: PropTypes.object,
  accent: PropTypes.string
}

export default CompanyQuoteProfile
