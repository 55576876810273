import React from 'react'
import PropTypes from 'prop-types'
import { getHolidayDates } from './api/otc/common'
import { getFixedIncome211ExemptionReasons } from './api/otc/company/fi-211'
import moment from 'moment'
import { getPrevBusinessDay } from './api/helper'

export const CompanyQuote211Context = React.createContext()

export const CompanyQuote211Consumer = CompanyQuote211Context.Consumer

export class CompanyQuote211ContextProvider extends React.Component {
  state = {
    isFilterLoaded: false,
    filterError: null,
    date: null
  }

  componentDidMount () {
    this.loadFilters()
  }

  loadFilters = async () => {
    const currentDate = moment()
    const tomorrowDate = moment().add(1, 'days')
    let holidayDates
    const params = {}
    let exemptionReasons, exemptionReasonsMap
    params.fromDate = currentDate.subtract(10, 'days').format('YYYY-MM-DD')
    params.toDate = currentDate.add(10, 'days').format('YYYY-MM-DD')

    let mostRecentDate
    try {
      holidayDates = await getHolidayDates(params)
      if (holidayDates && Array.isArray(holidayDates)) {
        mostRecentDate = getPrevBusinessDay(tomorrowDate, holidayDates, 1)
        mostRecentDate = moment(mostRecentDate, 'YYYY-MM-DD')
      } else {
        console.log('holiday API error, response is empty OR not  in proper format.')
        mostRecentDate = currentDate
      }
    } catch (e) {
      console.log('holiday API error', e)
      mostRecentDate = currentDate
    }
    try {
      exemptionReasons = await getFixedIncome211ExemptionReasons()
      if (exemptionReasons && Array.isArray(exemptionReasons)) {
        exemptionReasonsMap = new Map(exemptionReasons.map(reason => [reason.id, reason.name]))
      }
    } catch (e) {
      console.log('Fi 211 API error', e)
    }
    this.setState(state => ({
      date: mostRecentDate,
      exemptionReasons,
      exemptionReasonsMap,
      isFilterLoaded: true
    }))
  }

  render () {
    return (
      <CompanyQuote211Context.Provider value={{
        ...this.state
      }}>
        {this.props.children}
      </CompanyQuote211Context.Provider>
    )
  }
}

export const withCompanyQuote211Context = (Component) => {
  return (props) =>
    <CompanyQuote211Consumer>
      {(context) => {
        return <Component {...props} companyQuote211Context={context} />
      }}
    </CompanyQuote211Consumer>
}

CompanyQuote211ContextProvider.propTypes = {
  children: PropTypes.node.isRequired
}
