import api, { paginate } from '../../api'

export async function getResearchData (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    responseFormat: 'array',
    url: `/company/${params.symbol}/research`
  })
  return paginate({ records: response.data }, params)
}

export async function getResearchHome () {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    responseFormat: 'array',
    mockData: 'company/research/research-home',
    url: '/company/research/home'
  })
  return response.data
}
