import api from '../../api'

export async function getHolidayDates (params) {
  const response = await api.request({
    method: 'get',
    otcAPI: true,
    ignoreAuth: true,
    params,
    url: '/common/holidays'
  })
  return response.data
}
