import React from 'react'
import PropTypes from 'prop-types'
import styles from './CompanyQuoteResearch.module.scss'
import { getResearchData } from '../../api/otc/company/research'
import { logPageview } from '../../analytics'

import { template, fetch, expand } from '../../api/helper'
import api from '../../api/api'

import Outline from '../Outline'
import Table from '../Table'
import Loading from '../Loading'
import More from '../More'

const researchColumns = [{
  name: 'publishDate',
  header: 'Date'
}, {
  name: 'title',
  header: 'Title',
  target: 'blank',
  linkTo: (item) => {
    if (item.id) {
      return api.link({
        otcAPI: true,
        url: `/backend-content/company/research/${item.id}/content`
      })
    } else {
      if (!item.url) return null
      if (item.url.charAt(0) === '/') {
        return api.link({ otcAPI: true, url: `/backend-content${item.url}` })
      } else {
        return item.url
      }
    }
  }
}, {
  name: 'publisherName',
  header: 'Source',
  hideOnMobile: true
}]

class CompanyQuoteResearch extends React.PureComponent {
  constructor (props, context) {
    super(props, context)
    this.state = {
      researchData: template('array')
    }
  }

  componentDidMount () {
    this.reload()

    const title = `OTC Markets | ${this.props.company} | Research`
    logPageview(title)
  }

  reload = () => {
    const { company } = this.props

    fetch(this, getResearchData, 'researchData', {
      symbol: company,
      page: 1,
      pageSize: 10
    }, data => {
      if (!data) data = []
      if (data === '') data = []
      return data
    })
  }

  render () {
    const { accent } = this.props
    return (
      <div className={styles.CompanyQuoteResearch}>
        <Outline mode='heading' accent={accent}>Research Reports</Outline>
        <Loading
          type='table'
          emptyMessage='No Research Reports are available for this company.'
          loaded={this.state.researchData.loaded}
          reloading={this.state.researchData.reloading}
          error={this.state.researchData.error}
          data={this.state.researchData.data}>
          <Table
            columns={researchColumns}
            data={this.state.researchData.data.records}
            showHeader
            accent={accent}
          />
          <More onClick={expand(this, getResearchData, 'researchData')}
            disabled={this.state.researchData.expandEmpty} />
        </Loading>
      </div>
    )
  }
}

CompanyQuoteResearch.propTypes = {
  company: PropTypes.string.isRequired,
  accent: PropTypes.string
}

export default CompanyQuoteResearch
