import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import styles from './styles.module.scss'

const Hide = (props) =>
  <div
    className={classNames({
      [styles.hideSM]: props.sm,
      [styles.hideMD]: props.md,
      [styles.hideLG]: props.lg,
      [styles.inline]: props.inline
    })} >
    {props.children}
  </div>

Hide.propTypes = {
  sm: PropTypes.bool,
  md: PropTypes.bool,
  lg: PropTypes.bool,
  inline: PropTypes.bool,
  children: PropTypes.node.isRequired
}

export default Hide
